import React, { Component } from "react";
import {
  Profile,
  ProfileLeaderboard
} from "../../../stores/ProfileStore/Types";

interface Props {
  profileResource: Profile;
  leaderboard: ProfileLeaderboard;
  rank: string;
}

interface Leader {
  user: any;
  className?: any;
}

const countryFlagImage = countryCode => {
  let img;
  try {
    img = require(`../../../../../assets/images/flags/svg/${countryCode?.toLowerCase()}.svg`);
  } catch {
    img = "";
  }

  return img;
};

const Leader = ({ user, className }: Leader) => (
  <div className={`leader ${className}`}>
    <div className="left">
      <div className="position">
        <span>{user.rank}</span>
      </div>
      <div className="profile-pic">
        <img src={user.profile_picture} className="user-image" />
      </div>
      <div className="name">{user.name}</div>
      <div className="country">
        <img src={countryFlagImage(user.country)} />
      </div>
    </div>
    <div className="right">
      <div className="score">
        {user.xp} <span>XP</span>
      </div>
    </div>
  </div>
);

class Leaderboard extends Component<Props, {}> {
  renderLeaderboard = () => {
    const { profileResource, leaderboard } = this.props;

    if (leaderboard && leaderboard?.user_xp_total) {
      return (
        <>
          {leaderboard.section_above_user.map((user, i) => (
            <Leader user={user} key={i} />
          ))}

          <Leader
            user={{
              name: "You",
              profile_picture: profileResource.profile_picture,
              country: profileResource.country,
              xp: profileResource.xp,
              rank: leaderboard?.rank
            }}
            className="active"
          />
          {leaderboard.section_below_user.map((user, i) => (
            <Leader
              user={user}
              key={i}
              className={i === 1 ? "add-border" : ""}
            />
          ))}
        </>
      );
    } else {
      return (
        <>
          {leaderboard &&
            leaderboard.pool.map((user, i) => (
              <Leader
                user={user}
                key={i}
                className={i >= 1 ? "add-border" : ""}
              />
            ))}
        </>
      );
    }
  };

  render() {
    return (
      <>
        <div className="leaders">{this.renderLeaderboard()}</div>
        <div className="view-all">
          <a data-smooth-scroll-to="#profile-leaderboard" href-="#">
            View full leaderboard
          </a>
        </div>
      </>
    );
  }
}

export default Leaderboard;
