import React, { Component } from "react";
import { AxiosResponse, AxiosError } from "axios";
import { Modal, Transition } from "semantic-ui-react";
import { Api } from "../../utils/Api";
import { RootContext } from "../../stores/RootStore/Types";
import { notification } from "../../utils/helpers";

interface State {
  showAd: boolean;
}

class Ads extends Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      showAd: false,
    };
  }

  componentDidMount() {
    const client = new Api({});

    client
      .get<any>("/api/ads.json")
      .then((response: AxiosResponse<any>) => {
        const { data } = response;

        setTimeout(
          () =>
            this.setState({
              showAd: data.show_ad,
            }),
          5000,
        );
      })
      .catch((error: AxiosError) => {
        console.error(error.response?.statusText);
      });
  }

  closeAd = (e) => {
    e.preventDefault();

    this.setState({
      showAd: false,
    });

    const client = new Api({});

    client
      .get<any>("/user/expire_cookie.json?expire_cookie=black_friday_2024")
      .then((response: AxiosResponse<any>) => {
        this.setState({
          showAd: false,
        });
      })
      .catch((error: AxiosError) => {
        console.error(error.response?.statusText);
      });
  };

  copyCodeToClipboard = () => {
    let copyText = document.querySelector("#code");
    (copyText as any).select();
    document.execCommand("copy");
    notification("Copied voucher code to clipboard!", "alert");
  };

  render() {
    const { showAd } = this.state;

    return (
      <RootContext.Consumer>
        {({ loaded }) => {
          return (
            <div>
              <Transition.Group duration={400}>
                {showAd && (
                  <Modal
                    open={showAd}
                    onClose={this.closeAd}
                    className="ads-modal small"
                  >
                    <div className="">
                      <div className="closeButton">
                        <a
                          href="#"
                          className="ui right labeled button"
                          data-modal-close=""
                          id="modal_toggle"
                          onClick={this.closeAd}
                        >
                          <i className="close icon" />
                        </a>
                      </div>
                      <img
                        className="ui image"
                        src={require("../../../../assets/images/ads/black_friday.png")}
                        alt="cannes-lions-2022"
                      />
                      <p className="ad-text-one">
                        Treat yourself or a friend to a course or all access
                        pass.
                      </p>
                      <br />
                      <p className="ad-text-two">
                        Voucher Code: <br />
                      </p>
                      <div className="copy-container">
                        <div className="ui action input">
                          <input id="code" type="text" value="BLACKFRIDAY24" />
                          <button
                            onClick={this.copyCodeToClipboard}
                            className="ui icon labeled secondary button copy-btn"
                            data-clipboard-target="#code"
                          >
                            <i className="copy icon"></i>
                            <div className="bolder">COPY</div>
                          </button>
                        </div>
                      </div>
                      <p style={{ fontSize: "14px;" }}>
                        Valid 15th November - 7th December
                      </p>
                    </div>
                  </Modal>
                )}
              </Transition.Group>
            </div>
          );
        }}
      </RootContext.Consumer>
    );
  }
}

export default Ads;
